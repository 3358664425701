.label.label-btn.label-primary {
  background-color: @dark-sky-blue;
  &:hover, &:active, &.hover {
    background-color: darken(@dark-sky-blue, 5%);
  }
}

.label.label-darker {
  color: #FFF;
  background-color: darken(@bg-dark, 15%);
}

.label.label-btn.label-dark {
  color: #FFF;
  background-color: @bg-dark;
  &:hover, &:active, &.hover {
    color: #FFF;
    background-color: @bg-dark-hover;
    cursor: pointer;
  }
}

.label.label-success.label-outline {
  .label-outline(@brand-success);
}

.label.label-danger.label-outline {
  .label-outline(@brand-danger);
}

.label-outline(@color) {
  color: @color !important;
  background-color: transparent !important;
  border: solid 2px @color;
}


