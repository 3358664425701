#homeDiv {
  .todo-list {
    max-height: 420px;
    overflow-y: scroll;
  }
}
.todo-list {
  padding: 15px;
  > li {
    border: 0;
    background-color: @silver;
    .text {
      font-weight: 400;
      font-size: 1em;
      color: @bg-light-text-color;
    }
    .label {
      font-size: 0.7em;
    }
  }
}
