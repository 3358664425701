.main-sidebar {
  background-color: @bg-dark;
}

.sidebar-menu {
  > li:hover> a,
  > li.active> a,
  > li.menu-open> a {
    background-color: @bg-dark-hover;
  }
  > li> .treeview-menu {
    background-color: @bg-dark;
  }
}

.sidebar a {
  color: @bg-dark-text-color;
}

.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse {
  .sidebar-menu > li:hover {
    > a {
      //overflow: visible;
    }
    > a > span:not(.pull-right), //:not(.pull-right-container),
    > .treeview-menu {
      display: block !important;
      position: absolute;
      width: 235px;
      left: 50px;
    }

    //position the header & treeview menus
    > a > span {
      top: 0;
      margin-left: -3px;
      padding: 12px 5px 12px 20px;
      background-color: inherit;
    }
    > a > .pull-right-container {
      //display: block!important;
      position: relative !important;
      float: right;
      width: auto !important;
      left: 235px !important;
      top: -22px !important;
      z-index: 900;
      > .label:not(:first-of-type) {
        display: none;
      }
    }
    > .treeview-menu {
      top: 41px;
      margin-left: 0;
    }
  }
}

.main-header .sidebar-toggle,
.sidebar-toggle {
  border: 0;
  color: #FFF;
  width: 50px;
  height: 50px;
  outline: none !important;
  background-color: @base-color;
  border-radius: 0;
  &:before {
    content: '';
  }
  &:focus,
  &:active,
  &:hover {
    background-color: @base-color !important;
  }
}

.sidebar-mini,
.sidebar-mini.sidebar-collapse {
  .main-header .navbar {
    margin-left: 50px;
  }
}

.navbar-app-name {
  display: inline-block;
  font-size: 1.1em;
  padding: 0 20px;
  line-height: 50px;
  font-weight: 500;
  color: #FFF;
  img {
    max-height: 20px;
  }
  &:hover {
    color: #FFF;
  }
}

@media (max-width: 767px) {
  .main-sidebar {
    padding-top: 50px;
  }
}

.main-header .navbar {
  width: calc(~"100% - 50px");
}


.treeview-menu,
.sidebar-menu .treeview-menu {
  padding-left: 0;
  > li > a {
    color: @bg-dark-text-color;
    padding: 10px 15px;
    &:hover {
      background-color: @bg-dark-hover;
    }
  }
}

.sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
  padding: 0;
}

