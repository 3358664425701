.tooltip {
  .tooltip-arrow {
    border-bottom-color: @bg-dark !important;
  }
  &.top .tooltip-arrow {
    border-top-color: @bg-dark !important;
  }
  &.right .tooltip-arrow {
    border-right-color: @bg-dark !important;
  }
  &.left .tooltip-arrow {
    border-left-color: @bg-dark !important;
  }
  .tooltip-inner {
    background-color: @bg-dark;
    border-radius: 1px;
    padding: 2px 8px;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: center;
    max-width: 550px !important;
  }
}

.tooltip-list {
  padding-left: 0;
  list-style: none;
  margin: 0;
  > li:last-of-type {
    margin: 0;
  }
}
