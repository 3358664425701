button.dt-button,
div.dt-button,
a.dt-button,
.dt-button,
.btn-table {
  border-radius: 1px;
  background-image: none;
  height: @input-heigth;
  text-align: center;
  padding: 0px 21px;
  line-height: @input-heigth + 5;
  background-color: #FFF;
  border: @btn-table-border;
  color: @btn-table-color;

  &.btn-default,
  &.btn-danger,
  &.btn-success,
  &.btn-info,
  &.btn-primary {
    font-weight: 500;
  }

  &.btn-danger {
    color: #FFF;
    background-color: @brand-danger;
    border-color: transparent;
    &:hover, &:active, &.hover {
      border-color: transparent;
      background-color: darken(@brand-danger, 5%) !important;
      color: #FFF;
    }
  }

  &.btn-primary {
    color: #FFF;
    background-color: @dark-sky-blue;
    border-color: transparent;
    &:hover, &:active, &.hover {
      border-color: transparent;
      background-color: darken(@dark-sky-blue, 5%) !important;
      color: #FFF;
    }
  }

  &.dt-button-text {
    line-height: @input-heigth;
  }

  &:hover:not(.disabled),
  &:hover {
    background-image: none !important;
    background-color: @btn-table-bg-hover;
    border: @btn-table-border-hover;
  }
  .ion,
  .fa {
    font-size: 1.2rem;
} }

.input-group-addon {
  border: @btn-table-border;
  color: @btn-table-color;
  border-radius: 1px;
  padding: 6px 12px;
  background-color: #FFF;
  background-image: none;
  text-align: center;
  width: 1%;
  font-size: @input-font-size;
  .ion,
  .fa {
    font-size: 1.4rem;
  }
}

.btn,
.btn-info,
.btn-primary,
.btn-danger,
.btn-warning,
.btn-default,
.btn-success,
.btn-flat,
.btn.btn-flat {
  border-radius: 2px;
  border-color: transparent;
  &:hover {
    border-color: transparent;
  }
}

.btn:not(.btn-xs):not(.btn-md):not(.btn-lg):not(.btn-link):not(.btn-sm) {
  padding: 5px 35px;
  font-weight: 500;
  .calendar-header & {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.btn-primary {
  background-color: @dark-sky-blue;
  &:hover, &:active, &.hover {
    background-color: darken(@dark-sky-blue, 5%);
  }
}

.btn:not(.btn-xs) > .glyphicon:only-of-type,
.btn:not(.btn-xs) > .fa:only-of-type,
.btn:not(.btn-xs) > .ion:only-of-type, {
  font-size: 1.4rem;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: 500;
}

.dt-button > span {
  display: block;
  height: @input-heigth;
  width: 100%;
}
