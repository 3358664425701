.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.flex-checkbox-item {
  flex: 0 0 auto;
  width: calc(~"25% - 20px");
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  .radio, .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    label {
      padding: 5px;
      background: lighten(@light-blue-gray, 20);
      min-height: 44px;
      width: 100%;
    }
  }
  // iCheck checkboxes. This selector ignores the theme
  [class^="icheckbox"] {
    margin-right: 5px;
    background-color: #FFF;
  }
  @media @sm-and-up {
    &:nth-child(4n+0) {
      margin-right: 0;
    }

    &:nth-child(4n+1) {
      margin-left: 0;
    }
  }
  @media @mobile {
    flex: 0 0 100%;
  }
}
