.pagination> .active> a,
.pagination> .active> span,
.pagination> .active> a:hover,
.pagination> .active> span:hover,
.pagination> .active> a:focus,
.pagination> .active> span:focus {
  background-color: #FFF;
  color: @bg-dark;
  border-color: @blue-gray;
  border-width: 1px;
  font-weight: bold;
}

.pagination > li > a {
  border-color: @silver;
  color: @bg-dark;
  background-color: white;
}

.pagination> li> a:hover,
.pagination> li> span:hover,
.pagination> li> a:focus,
.pagination> li> span:focus {
  color: @bg-dark;
  border-color: @light-blue-gray;
}

.pagination> .disabled> span,
.pagination> .disabled> span:hover,
.pagination> .disabled> span:focus,
.pagination> .disabled> a,
.pagination> .disabled> a:hover,
.pagination> .disabled> a:focus {
  background-color: #FFF;
  border-color: @silver;
  color: #666;
}
