.modal-full {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px;
  > .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }
}

.modal-header {
  text-align: left;
}

.modal-backdrop + .modal-backdrop {
  opacity: 0;
}

table .modal .form-group {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

table .modal {
  text-align: left;
}

table .modal .form-group {
  .form-control, label {
    display: block;
    width: 100%;
    font-size: 1rem;
  }
}

.js-html-footer {
  position: relative;
  margin-top: -25px !important;
}
