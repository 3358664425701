.sweet-alert p {
  font-weight: 400;
}

.sweet-alert> p p {
  margin-bottom: 10px;
}

.custom-alert .sa-confirm-button-container button {
  margin: 5px;
}
