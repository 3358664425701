.box {
  .datepicker-inline {
    &,
    .datepicker-days {
      &,
      > table {
        width: 100%;
        td {
          &:hover {
            background-color: rgba(255, 255, 255, .3);
          }
          &.day {
            &.old,
            &.new {
              color: #777;
            }
          }
        }
      }
    }
  }
}