.form-control,
.select2-search__field {
  &:not(textarea) {
    line-height: @input-heigth;
    padding-top: 0;
    padding-bottom: 0;
  }
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
  background-color: #ffffff;
  font-weight: 400;
  font-size: @input-font-size;
  height: @input-heigth;
  color: @input-color;
  &::placeholder {
    font-size: 0.9em;
    text-align: left;
    color: @inactive-input-color;
  }
  &:focus,
  &:hover,
  &:active,
  &.select2-container--focus {
    border: solid 1px @input-border-color !important;
    color: @input-color;
  }
}

.form-control-feedback {
  height: @input-heigth;
  line-height: @input-heigth;
  &:before {
    color: @input-color;
  }
}

.select2-container .select2-search--inline .select2-search__field,
.select2-search--inline > .select2-search__field {
  padding-left: 5px;
  margin: 0 !important;
  font-size: @input-font-size !important;
  border: 0 !important;
  height: auto !important;
  line-height: 30px;
  &:focus,
  &:hover,
  &:active {
      border: 0 !important;
      height: auto !important;
    }
}

.select2-selection.select2-selection--multiple {
  min-height: 30px;
}

.select2-selection__placeholder {
  line-height: @input-heigth;
  height: @input-heigth;
  display: inline-block;
}

/**
 * iCheck
 */
[class*="_minimal-gray"] {
  margin-left: 5px;
}

.select2-container {
  width: 100% !important;
  display: block;
}

.select2-container--default,
.select2-selection {
  &.select2-container--focus,
  &:focus,
  &:active {
    outline: none;
  }
  .select2-selection--single {
    border-radius: 2px;
    padding: 0px 12px;
    height: @input-heigth;
    line-height: @input-heigth;
  }
}

.select2-dropdown {
  border: 1px solid @inactive-input-border-color;
  border-radius: 2px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: @bg-dark;
  color: white;
}

.select2-results__option {
  padding: 6px 14px;
  user-select: none;
  -webkit-user-select: none;
  color: @bg-dark;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
  color: @bg-darker;
  height: @input-heigth;
  line-height: @input-heigth;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 6px;
  padding-left: 20px;
  color: @bg-dark;
  height: @input-heigth;
  line-height: @input-heigth;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 28px;
  right: 3px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: 0;
  border-top-color: @bg-dark;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.select2-container--default .select2-selection--single {
  border-color: @inactive-input-border-color;
}

.select2-container--default.select2-container--open .select2-selection--single {
  border-color: @input-border-color;
}


.select2-dropdown,
.select2-search--inline {
  .select2-search__field {
    .form-control();
    padding: 0px 12px;
    &:focus {
      outline: none;
    }
  }
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba(243, 243, 243, 0.6);
  &,
  &:hover {
    color: @bg-dark;
  }
}

//Multiple select
.select2-container--default {
  .select2-selection--multiple {
    border: 1px solid @inactive-input-border-color;
    border-radius: 2px;
    &:focus {
      border-color: @dark-sky-blue;
    }
    .select2-search__field {
      width: 100% !important;
    }
  }
  &.select2-container--focus .select2-selection--multiple {
    border-color: @input-border-color;
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: @dark-sky-blue;
  border-color: darken(@dark-sky-blue, 5%);
  padding: 1px 10px;
  color: #fff;
  font-size: @input-font-size;
  font-weight: 500;
  max-width: calc(~"100% - 25px");
  overflow: hidden;
  text-overflow: ellipsis;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  margin-bottom: -4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: 5px;
  color: rgba(255, 255, 255, .7);
  &:hover {
    color: #fff;
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 10px;
}

label {
  color: @bg-dark;
  text-transform: uppercase;
  font-weight: 500;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  margin-right: 5px;
  margin-bottom: 1px;
}

.table.dataTable> tfoot> tr> th .form-control {
  padding-left: 7px;
  padding-right: 7px;
  height: 27px;
  line-height: 27px;
}

.dataTables_length .select2-container {
  width: auto !important;
  display: inline-block;
}

.select2-dropdown.table-select {
  width: 180px !important;
}

.select2-dropdown .select2-search__field::placeholder,
.select2-search--inline .select2-search__field::placeholder {
  color: #999;
  font-size: 1rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  li.select2-search.select2-search--inline {
    width: 100%;
  }
  li.select2-selection__choice + li.select2-search.select2-search--inline {
    width: auto;
  }
}

textarea[readonly].form-control {
  background-color: #fff;
  &:hover {
    border-color: #d2d6de !important;
  }
}
