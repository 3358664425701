.content-header {
  .make-row(0);
  clear: both;
  padding: @content-gutter 0 17px 0;
  margin-left: @content-gutter;
  margin-right: @content-gutter;
  border-bottom: solid 0.5px #dcdcdc;
  > .btn {
    float: right;
  }
  > .header-buttons {
    float: right;
    text-align: right;
    .modal {
      text-align: left;
    }
    .label {
      font-weight: 500;
      background-color: #FFF;
      padding: 5px 15px;
      margin-left: 10px;
      text-transform: uppercase;
      font-size: 1.2rem;
      display: inline-block;
      color: #777;
      > .fa-circle {
        margin-right: 5px;
      }
    }
  }
  h1 {
    float: left;
    font-size: 1.7em;
    font-weight: bold;
    color: @base-color;
    > .btn-link {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 3px;
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.1rem;
      text-align: left;
      color: #4a90e2;
      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
        color: @medium-blue;
      }
      .ion {
        display: inline-block;
        margin-right: 5px;
      }
      .label {
        font-weight: 500;
      display: inline-block;
        background-color: #FFF;
        padding: 5px 15px;
        margin-left: 10px;
        text-transform: uppercase;
        color: #777;
        > .fa-circle {
          margin-right: 5px;
        }
      }
    }
  }
}

.content-header-label-container {
  float: left;
  display: block;
  margin-bottom: 10px;
  width: 100%;
  > .label:first-of-type {
    display: inline-block;
    margin-left: 0;
  }
}
