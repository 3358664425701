.text-primary {
  &, &.btn-link {
    color: @light-blue;
  }
}

.text-danger {
  &, &.btn-link {
    color: @red;
  }
}

.text-success {
  &, &.btn-link {
    color: @green;
  }
}

.text-warning {
  &, &.btn-link {
    color: @yellow;
  }
}

.text-info {
  &, &.btn-link {
    color: @aqua;
  }
}

.yellow {
  color: #f3d712;
}
.green {
  color: #7ed321 !important;
}

.orange {
  color: #f6a623;
}

.red {
  color: #eb0000 !important;
}

.grey {
  color: #9b9b9b;
}

.blue {
  color: #4990e2;
}

.black {
  color: #4a4a4a;
}
