.box {
  border: @light-border;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: none;
}

.box.box-highligth {
  background: #FAFAFA;
  border: 0;
  > .box-header,
  > .box-header.with-border,
  & .box-header,
  & .box-header.with-border {
    border-bottom: 0;
  }
}

.box-header,
.box-header.with-border {
  border-bottom: @light-border;
}

.box-subtitle {
  margin-top: 5px;
  font-size: 1rem;
  font-weight: 400;
}

.box-header,
.box-title,
.box-header .box-title {
  font-size: 1.25rem;
  color: @bg-light-text-color;
  > a {
    color: @bg-light-text-color;
    &:hover,
    &:active,
    &:focus {
      color: @bg-dark-hover;
    }
  }
}

.box.box-solid {
  border: solid 0.5px #dcdcdc;
}

.box-scroll-table {
  overflow-y: visible;
  overflow-x: scroll;
}
