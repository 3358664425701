.grupo {
  padding: 10px;
  padding-bottom: 20px;
  border: 1px #ccc solid;

  &-render {
    margin-bottom: 5px;
  }

  &-editar {
    box-shadow: 0px 0px 10px #888888;
  }

  .excluir-grupo {
    position: absolute;
    font-size: 2.4em;
    color: #CCC;
    cursor: pointer;
    right: 10px;
  }

  .toggle-grupo {
    position: absolute;
    font-size: 2.4em;
    cursor: pointer;
    right: 40px;
    
    i {
      line-height: 16px;
    }
  }

  .pergunta {
    position: relative;
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;

    &-opcoes {
      position: absolute;
      top: 37px;
      transform: translateY(-50%);
      right: 0;

      .excluir-pergunta {
         font-size: 2.4em;
         cursor: pointer;
         line-height: 17px;
      }
    }
  }
}

.grid-versao-titulo {
    color: #ccc !important;
    background: #566973;
    padding: 5px 10px;
    margin: 0;
    font-size: 1em;
    text-transform: uppercase;
}

.grid-versao-descricao {
    padding: 5px 10px;
    margin: 0;
    font-size: 1em;
}

.block-seleciona-campo {
    cursor: not-allowed;
}

@media screen and(min-width: 40em) {
    .hidden-sm {
        display: none;
    }
}

textarea { resize: vertical; } 

.trim-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
